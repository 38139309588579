import React, { useState, useEffect } from 'react'
import { Book, Edit3, Headphones, MessageCircle } from 'react-feather'
import Tool from '../svg/tool'
import { graphql, useStaticQuery } from 'gatsby'
import {
	TabsSection,
	TabsContainer,
	TabsList,
	TabsItem,
	TabsLink,
} from './styles'
import localeCheck from '../../components/utils/locale-check'

const Tabs = ({ locale }) => {

	const defaultLocale = 'en-AU'
	const [pathName, setPathName] = useState('')

	useEffect(() => {
		const path = window.location.pathname
		// const alteredPath = path.substring(1)
		setPathName(path)
	}, [setPathName])
	const { tabs } = useStaticQuery(graphql`
		query getSupport {
			tabs: allContentfulPage(
				filter: {
					contentful_id: {
						in: 
							[
								"7vv29w2ZHiFKQccLfY2XVB", 
								"2F8kwuaezbHlGda1m1fPIG", 
								"2JhtBK7Tx60VtNIKeD6eoL", 
								"6LAjCqRqIKB2gF4WIQdY1G", 
								"2m8O4bbpRZm15Q97CtVGu"
							]
						}
					}
			) {
				edges {
					node {
						slug
						seoTitle
						node_locale
					}
				}
			}
		}
	`)

	const filterByLocale = (tab, locale) => {
		return tab.edges.filter((x, i) => {
			return x.node.node_locale === locale ? x : ''
		})
	}
	const tabsDetails = filterByLocale(tabs, locale)
	const getTitle = tabsDetails.map((item, i) => {
		return item.node.seoTitle
	})

	const supportArray = [
		'Knowledge Base',
		'Warranty Registration',
		'Repairs & Parts',
		'Find a Dealer',
		'Contact',
	]

	const icons = [
		{
			icon: <Book color="currentColor" width="30" height="30"></Book>,
		},
		{
			icon: <Edit3 color="currentColor" width="30" height="30"></Edit3>,
		},
		{
			icon: <Tool color="currentColor" width="30" height="30"></Tool>,
		},
		{
			icon: (
				<Headphones color="currentColor" width="30" height="30"></Headphones>
			),
		},
		{
			icon: (
				<MessageCircle
					color="currentColor"
					width="30"
					height="30"
				></MessageCircle>
			),
		},
	]

	const arrangedTab = getTitle.map((_, i) => {
		const xx = getTitle.indexOf(supportArray[i])
		return xx
	})

	const checkedLocale = localeCheck(locale, defaultLocale)
	return (
		<TabsSection>
			<TabsContainer>
				<TabsList>
					{arrangedTab.map((item, i) => {
						return (
							<TabsItem
								key={i}
								className={
									pathName === `${checkedLocale}/${tabsDetails[item].node.slug}`
										? 'active'
										: 'notactive'
								}
							>
								<TabsLink to={`${checkedLocale}/${tabsDetails[item].node.slug}`}>
									{icons[i].icon}
									<p>{tabsDetails[item].node.seoTitle}</p>
								</TabsLink>
							</TabsItem>
						)
					})}
				</TabsList>
			</TabsContainer>
		</TabsSection>
	)
}

export default Tabs
